<template>
  <div class="fragment">
    <template v-if="!$store.getters.getGenerateReportLoading">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_Before',
            'generateReport_OredersCount',
          ])"></div>
        <div class="before-time-block">
          <div class="before-time-block__item line">
            <div class="before-time-block__time">
              {{ $t('generateReport_Before.localization_value.value') }}
            </div>
            <div class="before-time-block__orders" v-if="$store.getters.getGenerateReportAM.length > 0">
              {{$store.getters.getGenerateReportCommonListAM.meta.orders_count}}
<!--              {{ getCountOrders($store.getters.getGenerateReportAM) }}-->
              {{ $t('generateReport_OredersCount.localization_value.value') }}
            </div>
          </div>
        </div>
      </div>

      <div class="site-table-wrap small-table table-overflow +"
           v-bind:class="{
            'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
            'report-table-mine': navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
           && navTabs.completed.subTabs.mine && navTabs.completed.subTabs.mine.active,
           'report-table-rest': navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
           && navTabs.completed.subTabs.rest && navTabs.completed.subTabs.rest.active,
           'report-table-all': navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
           && navTabs.completed.subTabs.all && navTabs.completed.subTabs.all.active,
        }">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_User',
            'generateReport_Admin',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_ShippingMethod',
            'generateReport_Quality',
            'generateReport_Cell',
          ])"></div>
        <table class="site-table"
               v-bind:class="{'site-table-loading': !_.has($store.getters.getGenerateReportCommonListAM, 'data')}"
        >
<!--               v-if="!isMobileFunc()"-->
          <thead>
          <tr>
            <th v-if="navTabs && navTabs.completed && navTabs.completed.active">
              <DefaultCheckbox
                  class="empty-label white brown-border noPrintable"
                  @input="(val) => $emit('selectAll', val)"
              />
            </th>
            <th>{{ $t('generateReport_User.localization_value.value') }}</th>
            <th v-if="navTabs && ((navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
              && navTabs.completed.subTabs.all && navTabs.completed.subTabs.all.active) || (navTabs.missing && navTabs.missing.active)
              || (navTabs.notPaid && navTabs.notPaid.active))">{{ $t('generateReport_Admin.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Order.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Name.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Inbound.localization_value.value') }}</th>
            <th>{{ $t('generateReport_ShippingMethod.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Quality.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Cell.localization_value.value') }}</th>
            <th width="100%"></th>
            <th></th>
          </tr>
          </thead>
          <tbody v-if="$store.getters.getGenerateReportAM.length > 0"
                 v-html="$store.getters.getGenerateReportAM"
                 v-bind:class="{'engraving-type': navTabs.engraving.active}"
          >

<!--          <template v-if="$store.getters.getGenerateReportAM.length > 0" v-html="$store.getters.getGenerateReportAM">-->

<!--          <template v-for="(report, index) of $store.getters.getGenerateReportAM">-->

<!--              <tr :key="index">-->

<!--                <td>-->
<!--                  {{report.user.user_personal_contact.user_full_name}}-->
<!--                </td>-->
<!--                <td>-->
<!--                  <router-link class="table-link"-->
<!--                               target="_blank"-->
<!--                               :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getFBMType(report.order.type) + '/' + report.order.id">-->
<!--                    {{ report.order.id }}-->
<!--                  </router-link>-->
<!--                  <br>-->
<!--                  {{ report.order.created_at | moment("DD MMM, YYYY") }}-->
<!--                  <br>-->
<!--                  {{ report.order.created_at | moment("HH:mm:ss") }}-->
<!--                </td>-->
<!--                <td>-->
<!--                  <div class="table-row">-->

<!--                    <div class="print-div&#45;&#45;mw280" style="max-width: 430px">-->
<!--                      <router-link class="table-link"-->
<!--                                   target="_blank"-->
<!--                                   :to="$store.getters.GET_PATHS.products + '/' + report.product.id + ($route.query.storage ? `?storageId=${$route.query.storage}` : '')">-->
<!--                        {{ report.product.combo_name }}-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <router-link class="table-link"-->
<!--                               target="_blank"-->
<!--                               v-if="report.product.last_inbound && _.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id"-->
<!--                               :to="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +-->
<!--                                getConsolidationTypeById(report.product.last_inbound.consolidation_destination_id).name + '/' + report.product.last_inbound.order_model_id">-->
<!--                    <span v-if="report.product.last_inbound.order_model_id">{{report.product.last_inbound.order_model_id}}</span>-->
<!--                  </router-link>-->
<!--                  <router-link class="table-link"-->
<!--                               target="_blank"-->
<!--                               v-else-if="report.product.last_inbound && _.has(report.product.last_inbound, 'inbound_id') && report.product.last_inbound.inbound_id"-->
<!--                               :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +-->
<!--                      getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id">-->
<!--                    <span v-if="report.product.last_inbound.order_model_id">{{report.product.last_inbound.order_model_id}}</span>-->
<!--                  </router-link>-->
<!--                  <br>-->
<!--                  <template v-if="_.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id">-->
<!--                    {{ report.product.last_inbound.consolidation_created_at | moment("DD MMM, YYYY") }}-->
<!--                    <br>-->
<!--                    {{ report.product.last_inbound.consolidation_created_at | moment("HH:mm:ss") }}-->
<!--                  </template>-->
<!--                  <template v-else-if="report.product.last_inbound">-->
<!--                    {{ report.product.last_inbound.created_at | moment("DD MMM, YYYY") }}-->
<!--                    <br>-->
<!--                    {{ report.product.last_inbound.created_at | moment("HH:mm:ss") }}-->
<!--                  </template>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <template v-if="report.order.delivery_method && report.order.delivery_method.name === 'DPD.cz'">-->
<!--                    DPD-->
<!--                  </template>-->
<!--                  <div v-else-if="report.order.delivery_method.name">{{report.order.delivery_method.name}}</div>-->
<!--                </td>-->
<!--                <td>-->
<!--                  {{ report.product.order_item_quantity }}-->
<!--                </td>-->
<!--                <td>-->
<!--                  <template v-for="comboName of report.product.items_in_storage">-->
<!--                    <div v-if="comboName.cell !== null && comboName.cell.combo_name !== null" :key="comboName.id">-->
<!--                      {{ comboName.cell.combo_name }} ({{comboName.quantity}})-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </td>-->

<!--                <td width="100%"></td>-->
<!--                <td align="right">-->
<!--                  <router-link class="table-link mr-3 noPrintable"-->
<!--                               target="_blank"-->
<!--                               :to="$store.getters.GET_PATHS.ordersFBM + '/' + getFBMType(report.order.type) + '/' + report.order.id">-->
<!--                    {{ $t('generateReport_edit.localization_value.value') }}-->
<!--                  </router-link>-->
<!--                </td>-->
<!--              </tr>-->

<!--          </template>-->
          </tbody>
        </table>

        <div class="table-card" v-if="false && isMobileFunc() && _.has($store.getters.getGenerateReportCommonListAM, 'data')"
             v-html="$store.getters.getGenerateReportAM"
             v-bind:class="{'engraving-type': navTabs.engraving.active}"
        >
<!--          <div class="table-card__list pt-0">-->
<!--            <div class="table-card__item"-->
<!--                 v-for="(report, index) in $store.getters.getGenerateReportAM"-->
<!--                 :key="index">-->
<!--              <GenerateReportPageTableMobile-->
<!--                  :report="report"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <!--      <div class="table-bottom-btn">-->
        <!--        <div class="table-bottom-btn__inner">-->
        <!--          <ShowMore :count="'25'" @click.native="$emit('showMore')"/>-->
        <!--          <ExportBtn class="table-bottom-btn__right"/>-->
        <!--        </div>-->
        <!--      </div>-->


        <StatisticPopup
            v-if="isModalStatisticPopup"
            :userId="userId"
            @close="isModalStatisticPopup = false; userId = null"
        />

      </div>
<!--      <div class="pt-2 pb-3" v-if="$store.getters.getGenerateReportAM.length === 0 && !_.has($store.getters.getGenerateReportCommonListAM, 'data')">-->
<!--        <DotsShadowPreloader/>-->
<!--      </div>-->

      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'generateReport_BetweenMiddle',
          'generateReport_OredersCount',
        ])"></div>
        <div class="before-time-block">
          <div class="before-time-block__item line">
            <div class="before-time-block__time">
              {{ $t('generateReport_BetweenMiddle.localization_value.value') }}
            </div>
            <div class="before-time-block__orders">
              {{$store.getters.getGenerateReportCommonListMD.meta.orders_count}}
<!--                {{ getCountOrders($store.getters.getGenerateReportMD) }}-->
              {{ $t('generateReport_OredersCount.localization_value.value') }}
            </div>
          </div>
        </div>
      </div>

      <div class="site-table-wrap small-table table-overflow"
           v-bind:class="{
            'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
            'report-table-mine': navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
           && navTabs.completed.subTabs.mine && navTabs.completed.subTabs.mine.active,
           'report-table-rest': navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
           && navTabs.completed.subTabs.rest && navTabs.completed.subTabs.rest.active,
           'report-table-all': navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
           && navTabs.completed.subTabs.all && navTabs.completed.subTabs.all.active,
        }">
          <div class="admin-edit" @click="editTranslate([
            'generateReport_User',
            'generateReport_Admin',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_ShippingMethod',
            'generateReport_Quality',
            'generateReport_Cell',
          ])"></div>
<!--        !isMobileFunc() &&-->
          <table class="site-table" v-if=" _.has($store.getters.getGenerateReportCommonListMD, 'data')">
            <thead>
            <tr>
              <th v-if="navTabs && navTabs.completed && navTabs.completed.active"></th>
              <th>{{ $t('generateReport_User.localization_value.value') }}</th>
              <th v-if="navTabs && ((navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
              && navTabs.completed.subTabs.all && navTabs.completed.subTabs.all.active) || (navTabs.missing && navTabs.missing.active)
              || (navTabs.notPaid && navTabs.notPaid.active))">{{ $t('generateReport_Admin.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Order.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Name.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Inbound.localization_value.value') }}</th>
              <th>{{ $t('generateReport_ShippingMethod.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Quality.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Cell.localization_value.value') }}</th>
              <th width="100%"></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(report, index) of $store.getters.getGenerateReportMD" :key="index">

              <td v-if="navTabs && navTabs.completed && navTabs.completed.active">
                <DefaultCheckbox
                    v-if="(index === 0 || (index > 0 && $store.getters.getGenerateReportMD[index - 1] && $store.getters.getGenerateReportMD[index - 1].order
                  && $store.getters.getGenerateReportMD[index - 1].order.id && report.order && report.order.id && report.order.id !== $store.getters.getGenerateReportMD[index - 1].order.id))
                  && !(report.order_bins && report.order_bins.length > 0)"
                    class="empty-label"
                    :dataOrderId="report.order.id"
                />
              </td>
              <td>
<!--                <TableUserColumn-->
<!--                    :item="{user: {-->
<!--                    id: report.user.id,-->
<!--                    show_n_mark: report.user.show_n_mark,-->
<!--                    user_settings: report.user.user_settings,-->
<!--                    user_personal_contact :{user_full_name: report.user.user_full_name},-->
<!--                  }}"-->
<!--                />-->
                <TableUserColumn
                    :item="report"/>
              </td>
              <td v-if="navTabs && ((navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
              && navTabs.completed.subTabs.all && navTabs.completed.subTabs.all.active) || (navTabs.missing && navTabs.missing.active)
              || (navTabs.notPaid && navTabs.notPaid.active))">
                <div class="table-link"
                     v-if="report.order_admin && !(Array.isArray(report.order_admin) && report.order_admin.length === 0)"
                     :data-user-id="report.order_admin.id"
                     :data-user-full-name="report.order_admin.user_personal_contact.user_full_name"
                     :data-user-email="report.order_admin.email"
                >
                  {{report.order_admin.user_personal_contact.user_full_name}}
                </div>
              </td>
              <td>
                <span v-if="report.order.customization_complete === 2" class="order-customization-complete"></span>
                <router-link class="table-link"
                             target="_blank"
                             :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getFBMType(report.order.type) + '/' + report.order.id">
                  {{ report.order.id }}
                </router-link>
                <br>
                {{ report.order.created_at | moment("DD MMM, YYYY") }}
                <br>
                {{ report.order.created_at | moment("HH:mm:ss") }}
              </td>
              <td>
                <div class="table-row">
                  <div class="print-div--mw280" style="max-width: 430px">
                    <router-link class="table-link white-space-normal"
                                 target="_blank"
                                 :to="$store.getters.GET_PATHS.products + '/' + report.product.id + ($route.query.storage ? `?storageId=${$route.query.storage}` : '')">
                      {{ report.product.combo_name }}
                    </router-link>
                  </div>
<!--                  <TooltipTextHelper :fullTextWidth="300">-->
<!--                    <template slot="text">-->
<!--                      <router-link class="table-link"-->
<!--                                   target="_blank"-->
<!--                                   :to="$store.getters.GET_PATHS.products + '/' + report.product.id">-->
<!--                        {{ report.product.combo_name }}-->
<!--                      </router-link>-->
<!--                    </template>-->
<!--                    <template slot="title">-->
<!--                      {{ report.product.combo_name }}-->
<!--                    </template>-->
<!--                  </TooltipTextHelper>-->
                </div>
              </td>
              <td>

                <router-link class="table-link"
                             target="_blank"
                             v-if="report.product.last_inbound && _.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id"
                             :to="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
                                getConsolidationTypeByDestinationId(report.product.last_inbound.consolidation_destination_id) + '/' + report.product.last_inbound.order_model_id">
                  <span v-if="report.product.last_inbound.order_model_id">{{report.product.last_inbound.order_model_id}}</span>
                </router-link>
                <router-link class="table-link"
                             target="_blank"
                             v-else-if="report.product.last_inbound && _.has(report.product.last_inbound, 'inbound_id') && report.product.last_inbound.inbound_id"
                             :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id">
                  <ValueHelper
                      :value="report"
                      :deep="'product.last_inbound.order_model_id'"
                  />
                </router-link>
                <br>
                <template v-if="_.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id">
                  {{ report.product.last_inbound.consolidation_created_at | moment("DD MMM, YYYY") }}
                  <br>
                  {{ report.product.last_inbound.consolidation_created_at | moment("HH:mm:ss") }}
                </template>
                <template v-else-if="report.product.last_inbound">
                  {{ report.product.last_inbound.created_at | moment("DD MMM, YYYY") }}
                  <br>
                  {{ report.product.last_inbound.created_at | moment("HH:mm:ss") }}
                </template>
              </td>
              <td>
                <template v-if="report.order.delivery_method && report.order.delivery_method.name === 'DPD.cz'">
                  DPD
                </template>
                <ValueHelper
                    v-else
                    :value="report"
                    :deep="'order.delivery_method.name'"
                />
              </td>
              <td>
                {{ report.product.order_item_quantity }}
              </td>
              <td>
                <template v-for="comboName of report.product.items_in_storage">
                  <div v-if="comboName.cell !== null && comboName.cell.combo_name !== null" :key="comboName.id">
                    {{ comboName.cell.combo_name }} ({{comboName.quantity}})
                  </div>
                </template>
              </td>

              <td width="100%"></td>
              <td align="right">
<!--                    v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"-->
                <LinkButton
                    class="mr-3 noPrintable"
                    :link="$store.getters.GET_PATHS.ordersFBM + '/' + getFBMType(report.order.type) + '/' + report.order.id"
                    :value="$t('generateReport_edit.localization_value.value')"
                    :type="'edit'"
                    :target="'_blank'"
                />
              </td>
            </tr>
            </tbody>
          </table>

          <div class="table-card" v-if="false && isMobileFunc() && _.has($store.getters.getGenerateReportCommonListMD, 'data')">
            <div class="table-card__list pt-0">
              <div class="table-card__item"
                   v-for="(report, index) in $store.getters.getGenerateReportMD"
                   :key="index">
                <GenerateReportPageTableMobile
                    :navTabs="navTabs"
                    :index="index"
                    :report="report"
                />
              </div>
            </div>
          </div>
          <!--      <div class="table-bottom-btn">-->
          <!--        <div class="table-bottom-btn__inner">-->
          <!--          <ShowMore :count="'25'" @click.native="$emit('showMore')"/>-->
          <!--          <ExportBtn class="table-bottom-btn__right"/>-->
          <!--        </div>-->
          <!--      </div>-->
        </div>

      <div class="pt-2 pb-3" v-if="$store.getters.getGenerateReportMD.length === 0 && !_.has($store.getters.getGenerateReportCommonListMD, 'data')">
        <DotsShadowPreloader/>
      </div>

      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_After',
            'generateReport_OredersCount',
          ])"></div>
        <div class="before-time-block">
          <div class="before-time-block__item line">
            <div class="before-time-block__time">
              {{ $t('generateReport_After.localization_value.value') }}
            </div>
            <div class="before-time-block__orders">
              {{$store.getters.getGenerateReportCommonList.meta.orders_count}}
<!--              {{ getCountOrders($store.getters.getGenerateReport) }}-->
              {{ $t('generateReport_OredersCount.localization_value.value') }}
            </div>
          </div>
        </div>
      </div>

      <div class="site-table-wrap small-table table-overflow"
           v-bind:class="{
            'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
            'report-table-mine': navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
           && navTabs.completed.subTabs.mine && navTabs.completed.subTabs.mine.active,
           'report-table-rest': navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
           && navTabs.completed.subTabs.rest && navTabs.completed.subTabs.rest.active,
           'report-table-all': navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
           && navTabs.completed.subTabs.all && navTabs.completed.subTabs.all.active,
        }">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_User',
            'generateReport_Admin',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_ShippingMethod',
            'generateReport_Quality',
            'generateReport_Cell',
          ])"></div>
<!--        !isMobileFunc() &&-->
        <table class="site-table" v-if="_.has($store.getters.getGenerateReportCommonList, 'data')">
          <thead>
          <tr>
            <th v-if="navTabs && navTabs.completed && navTabs.completed.active"></th>
            <th>{{ $t('generateReport_User.localization_value.value') }}</th>
            <th v-if="navTabs && ((navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
              && navTabs.completed.subTabs.all && navTabs.completed.subTabs.all.active) || (navTabs.missing && navTabs.missing.active)
              || (navTabs.notPaid && navTabs.notPaid.active))">{{ $t('generateReport_Admin.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Order.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Name.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Inbound.localization_value.value') }}</th>
            <th>{{ $t('generateReport_ShippingMethod.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Quality.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Cell.localization_value.value') }}</th>
            <th width="100%"></th>
            <th class="noPrintable"></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(report, index) of $store.getters.getGenerateReport" :key="index">

            <td v-if="navTabs && navTabs.completed && navTabs.completed.active">
              <DefaultCheckbox
                  v-if="(index === 0 || (index > 0 && $store.getters.getGenerateReport[index - 1] && $store.getters.getGenerateReport[index - 1].order
                  && $store.getters.getGenerateReport[index - 1].order.id && report.order && report.order.id && report.order.id !== $store.getters.getGenerateReport[index - 1].order.id))
                  && !(report.order_bins && report.order_bins.length > 0)"
                  class="empty-label"
                  :dataOrderId="report.order.id"
              />
            </td>
            <td>
              <TableUserColumn
                  :item="report"/>

<!--              :item="{user: {-->
<!--              id: report.user.id,-->
<!--              show_n_mark: report.user.show_n_mark,-->
<!--              user_settings: report.user.user_settings,-->
<!--              user_personal_contact :{user_full_name: report.user.user_full_name},-->
<!--              }}"-->
            </td>
            <td v-if="navTabs && ((navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
              && navTabs.completed.subTabs.all && navTabs.completed.subTabs.all.active) || (navTabs.missing && navTabs.missing.active)
              || (navTabs.notPaid && navTabs.notPaid.active))">
              <div class="table-link"
                   v-if="report.order_admin && !(Array.isArray(report.order_admin) && report.order_admin.length === 0)"
                   :data-user-id="report.order_admin.id"
                   :data-user-full-name="report.order_admin.user_personal_contact.user_full_name"
                   :data-user-email="report.order_admin.email"
              >
                {{report.order_admin.user_personal_contact.user_full_name}}
              </div>
            </td>
            <td>
              <span v-if="report.order.customization_complete === 2" class="order-customization-complete"></span>
              <router-link class="table-link"
                           target="_blank"
                           :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getFBMType(report.order.type) + '/' + report.order.id">
                {{ report.order.id }}
              </router-link>
              <br>
              {{ report.order.created_at | moment("DD MMM, YYYY") }}
              <br>
              {{ report.order.created_at | moment("HH:mm:ss") }}
            </td>
            <td>
              <div class="table-row">
                <div class="print-div--mw280" style="max-width: 430px">
                  <router-link class="table-link white-space-normal"
                               target="_blank"
                               :to="$store.getters.GET_PATHS.products + '/' + report.product.id + ($route.query.storage ? `?storageId=${$route.query.storage}` : '')">
                    {{ report.product.combo_name }}
                  </router-link>
                </div>
<!--                <TooltipTextHelper :fullTextWidth="300">-->
<!--                  <template slot="text">-->
<!--                    <router-link class="table-link"-->
<!--                                 target="_blank"-->
<!--                                 :to="$store.getters.GET_PATHS.products + '/' + report.product.id">-->
<!--                      {{ report.product.combo_name }}-->
<!--                    </router-link>-->
<!--                  </template>-->
<!--                  <template slot="title">-->
<!--                    {{ report.product.combo_name }}-->
<!--                  </template>-->
<!--                </TooltipTextHelper>-->
              </div>
            </td>
            <td>
              <router-link class="table-link"
                           target="_blank"
                           v-if="report.product.last_inbound && _.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id"
                           :to="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
                                getConsolidationTypeByDestinationId(report.product.last_inbound.consolidation_destination_id) + '/' + report.product.last_inbound.order_model_id">
                <span v-if="report.product.last_inbound.order_model_id">{{report.product.last_inbound.order_model_id}}</span>
              </router-link>
              <router-link class="table-link"
                           target="_blank"
                           v-else-if="report.product.last_inbound && _.has(report.product.last_inbound, 'inbound_id') && report.product.last_inbound.inbound_id"
                           :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id">
                <ValueHelper
                    :value="report"
                    :deep="'product.last_inbound.order_model_id'"
                />
              </router-link>
              <br>
              <template v-if="_.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id">
                {{ report.product.last_inbound.consolidation_created_at | moment("DD MMM, YYYY") }}
                <br>
                {{ report.product.last_inbound.consolidation_created_at | moment("HH:mm:ss") }}
              </template>
              <template v-else-if="report.product.last_inbound">
                {{ report.product.last_inbound.created_at | moment("DD MMM, YYYY") }}
                <br>
                {{ report.product.last_inbound.created_at | moment("HH:mm:ss") }}
              </template>
            </td>
            <td>
              <template v-if="report.order.delivery_method && report.order.delivery_method.name === 'DPD.cz'">
                DPD
              </template>
              <ValueHelper
                  v-else
                  :value="report"
                  :deep="'order.delivery_method.name'"
              />
            </td>
            <td>
              {{ report.product.order_item_quantity }}
            </td>
            <td>
              <template v-for="comboName of report.product.items_in_storage">
                <div v-if="comboName.cell !== null && comboName.cell.combo_name !== null" :key="comboName.id">
                  {{ comboName.cell.combo_name }} ({{comboName.quantity}})
                </div>
              </template>
            </td>

            <td width="100%"></td>
            <td align="right" >
<!--                  v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"-->
              <LinkButton
                  class="mr-3 noPrintable"
                  :link="$store.getters.GET_PATHS.ordersFBM + '/' + getFBMType(report.order.type) + '/' + report.order.id"
                  :value="$t('generateReport_edit.localization_value.value')"
                  :type="'edit'"
                  :target="'_blank'"
              />
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="false && isMobileFunc() && _.has($store.getters.getGenerateReportCommonList, 'data')">
          <div class="table-card__list pt-0">
            <div class="table-card__item"
                 v-for="(report, index) in $store.getters.getGenerateReport"
                 :key="index">
              <GenerateReportPageTableMobile
                  :navTabs="navTabs"
                  :index="index"
                  :report="report"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="pt-2 pb-3" v-if="$store.getters.getGenerateReport.length === 0 && !_.has($store.getters.getGenerateReportCommonList, 'data')">
        <DotsShadowPreloader/>
      </div>

    </template>

    <div class="table-bottom-btn">
      <div class="table-bottom-btn__inner">
      </div>
    </div>


  </div>
</template>

<script>
// import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
// import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
// import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import GenerateReportPageTableMobile
  from "@/components/modules/GenerateReportPageModule/components/GenerateReportPageTable/GenerateReportPageTableAdmin/GenerateReportPageTable/GenerateReportPageTableMobile/GenerateReportPageTableMobile";
import {FBM_ORDER_TYPES} from "@/staticData/staticVariables";
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import {ordersInboundMixin} from "@/mixins/ordersInbound/ordersInboundMixin";
import DotsShadowPreloader from "@/components/coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import {consolidationMixin} from "@/mixins/consolidationMixins/consolidationMixin";
import StatisticPopup
  from "@/components/coreComponents/TableComponents/TableUserColumn/popups/StatisticPopup/StatisticPopup";
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
// import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
// import ProductsToWarehouse from "../../../../popups/ProductsToWarehouse/ProductsToWarehouse";


export default {
  name: "NewPaymentsTable",

  components: {
    DefaultCheckbox,
    StatisticPopup,
    DotsShadowPreloader,
    TableUserColumn,
    GenerateReportPageTableMobile,
    ValueHelper,
    // TooltipTextHelper,
    // TooltipBtn,
    // MainButton,
    // DefaultInput,
    LinkButton,
    // ProductsToWarehouse,
  },

  mixins: [mixinDetictingMobile, ordersInboundMixin, consolidationMixin],

  props: {
    navTabs: Object,
  },
  watch: {
    getGenerateReportAMList(val) {
      if(val.length === 0) return
      setTimeout(() => {
        let elements = document.querySelectorAll('div[data-user-id]')
        // elements
        for (let element of elements) {
          element.addEventListener('click', () => {
            this.userId = Number(element.getAttribute('data-user-id'))
            this.isModalStatisticPopup = true
          })
        }

      let toggleHideElements = document.querySelectorAll('.js-html-item-show')
        toggleHideElements.forEach(item => {
          item.addEventListener('click', () => {
            item.closest('.table-card__item').querySelector('.table-card__item-body').classList.toggle('js-html-item-body--hide')
          })
        })
      }, 200)
    }
  },

  computed: {
    getGenerateReportAMList () {
      return this.$store.getters.getGenerateReportAM
    }
  },

  data() {
    return {
      orderId: '',
      showFilter: false,
      isMobile: false,

      showContent: false,

      visibleCount: 100,
      visibleDone: false,

      isModalStatisticPopup: false,
      userId: null,
    }
  },

  methods: {

    // loadFullCount(fullCount) {
    //   setTimeout(() => {
    //     if(fullCount - this.visibleCount < 500) {
    //       this.visibleCount = fullCount
    //       this.visibleDone = true
    //     } else {
    //       this.visibleCount = this.visibleCount + 500
    //     }
    //     if(this.visibleCount < fullCount) {
    //       this.loadFullCount(fullCount)
    //     }
    //   }, 2000)
    // },


    getCountOrders(orders) {
      let array = []
      orders.map(order => {
        if(!this._.find(array, {id: order.order.id})){
          array.push(order.order)
        }
      })
      return array.length
    },

    getFBMType(type) {
      let actualType = ''

      switch (type) {
        case 'consolidation':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name
          break
        case 'sklad_create':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name
          break
        case 'user_create':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_LABEL.name
          break
      }

      return actualType
    },

    closePopup() {
      // this.isModalProductsToWarehouse = false
    },

    cantDeleteProduct() {
      let text = {
        title: 'Ooops',
        txt: 'Sorry, you cannot uninstall this product',
        no: 'Ok'
      }

      let confirm = () => {
        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    removeProduct() {
      let text = {
        title: 'Delete Product?',
        txt: 'Are you sure you want to Delete Product?',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        // this.$store.dispatch('deleteShopPermission', id).then((response) => {
        //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //     let shops = this.$store.getters.getShops
        //     shops.map((item, index) => {
        //       if(item.id === id) {
        //         shops[index].shop_oauth_param.key_and_token_exist = 0
        //       }
        //     })
        //   } else {
        //     setTimeout(() => {
        //       this.openNotify('success', 'Произошла ошибка')
        //     }, 200)
        //   }
        // })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    }
  }

}

</script>

<style scoped lang="scss">

.site-table-loading{
  opacity: 0.5;
}

.comment-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.table-manage-list {
  width: 100%;

  .manager-btn {
    max-width: 100%;
  }
}


@media (max-width: 1900px) {
  .comment-row {
    width: 200px;
  }
}

@media (max-width: 1400px) {
  .comment-row {
    width: 150px;
  }
}

//.site-table thead th:first-child,
//.site-table tbody td:first-child{
//  padding-right: 0;
//}

//.site-table thead th:nth-child(2),
//.site-table tbody td:nth-child(2){
//  padding-left: 0;
//}

.site-table thead th:last-child,
.site-table tbody td:last-child {
  padding-right: 0;
}

.small-table th,
.small-table td {
  white-space: nowrap;
}

.small-table th:nth-child(4),
.small-table td:nth-child(4) {
  white-space: initial;
}

@media (min-width: 1300px) {
  .site-table thead th:nth-child(1) {
    min-width: 250px;
  }

  .site-table thead th:nth-child(2) {
    min-width: 100px;
  }

  .site-table thead th:nth-child(3) {
    min-width: 100px;
  }

  .site-table thead th:nth-child(4) {
    min-width: 320px;
  }

  .site-table thead th:nth-child(5) {
    min-width: 101px;
  }

  .site-table thead th:nth-child(6) {
    min-width: 230px;
  }

  .site-table thead th:nth-child(7) {
    min-width: 85px;
  }

  .site-table thead th:nth-child(8) {
    min-width: 95px;
  }

  .report-table-mine, .report-table-rest {
    .site-table thead th:nth-child(1) {
      min-width: 50px;
    }

    .site-table thead th:nth-child(2) {
      min-width: 250px;
    }
  }

  .report-table-all {
    .site-table thead th:nth-child(1) {
      min-width: 50px;
    }

    .site-table thead th:nth-child(2) {
      min-width: 250px;
    }

    .site-table thead th:nth-child(3) {
      min-width: 100px;
    }

    .site-table thead th:nth-child(4) {
      min-width: 100px;
    }

    .site-table thead th:nth-child(5) {
      min-width: 320px;
    }

    .site-table thead th:nth-child(6) {
      min-width: 101px;
    }

    .site-table thead th:nth-child(7) {
      min-width: 230px;
    }

    .site-table thead th:nth-child(8) {
      min-width: 85px;
    }

    .site-table thead th:nth-child(9) {
      min-width: 95px;
    }
  }
}

.table-overflow {
  overflow: auto;
}

.order-customization-complete{
  display: block;
  width: 20px;
  height: 20px;
  background: url('../../../../../../../assets/img/common/laser.svg') center center no-repeat;
  background-size: contain;
  margin: 0 0 0 auto;
}

</style>


<style>

.engraving-type td:first-child,
.engraving-type td:nth-child(3) {
  display: none;
}

td div[data-user-id] {
  cursor: pointer;
  display: inline;
}

@media (max-width: 1860px) {
  .table-link.white-space-normal{
    max-width: 330px !important;
  }
}

.engraving-ico-wrap {
  position: relative;
}

.engraving-ico {
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  background: black;
  left: 160px;
  background: url("../../../../../../../assets/img/orders-fbm-group/ico-engraving.svg") center center no-repeat;
}

.small-table .order-customization-complete{
  display: block;
  width: 20px;
  height: 20px;
  background: url('../../../../../../../assets/img/common/laser.svg') center center no-repeat;
  background-size: contain;
  margin: 0 0 0 auto;
}



</style>